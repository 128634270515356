/* eslint-disable */
import React, {
    createContext,
    useCallback,
    useContext,
    useState,
    useEffect,
} from 'react';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { useCookies } from 'react-cookie';

import { UserDataName } from '../constants/cookies.json';
import { Endpoints } from '../constants/apiConfig.json';
import { RoutesData } from '../routes/index';
import useAPI from '../hooks/useAPI';

interface LoginRequest {
    Username: string;
    Password: string;
}

interface LoginResponse {
    username: string;
    roles: number;
    token: string;
    tipoPessoa: number;
    ferias: number;
}

interface ValidadePermissionResponse {
    idFkFuncao: number;
}

interface SessionContextData {
    user?: LoginResponse;
    login: (user: LoginRequest) => Promise<LoginResponse>;
    logout: () => void;
    validadePermission: () => Promise<number>;
    getUserId: () => null | string
}

const SessionContext: React.Context<SessionContextData> = createContext<SessionContextData>(
    {
        login: () => new Promise(() => { }),
        logout: () => { },
        validadePermission: () => new Promise(() => { }),
        getUserId: () => null
    }
);

function getUser(userCookie: Record<string, LoginResponse>) {
    const user = userCookie[UserDataName];
    return typeof user === 'object' ? user : undefined;
}

const SessionProvider: React.FC = ({ children }) => {

    const [userCookie, setUserCookie, removeUserCookie] = useCookies<string, Record<string, LoginResponse>>([UserDataName]);

    const { api, setApiConfig } = useAPI();

    const [sessionReady, setSessionReady] = useState<boolean>(false);

    const subscribeUser = useCallback((data: LoginResponse) => {
        setUserCookie(UserDataName, data, { path: '/' });
    }, [setUserCookie]);

    const unsubscribeUser = useCallback(() => {
        removeUserCookie(UserDataName);
    }, [removeUserCookie]);

    const login: (
        loginRequest: LoginRequest
    ) => Promise<LoginResponse> = useCallback(
        (loginRequest: LoginRequest) => {
            return new Promise<LoginResponse>((resolve, reject) => {
                api<LoginResponse>({
                    method: "POST",
                    url: Endpoints.Login,
                    data: qs.stringify(loginRequest),
                    useLoading: true,
                })
                    .then((response: AxiosResponse<LoginResponse>) => {
                        if (response.status === 200) {
                            subscribeUser(response.data);
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        [api, subscribeUser]
    );

    const logout: () => void = useCallback(() => {
        setSessionReady(false);
        unsubscribeUser();
        setApiConfig({ headers: { Authorization: undefined } });
        window.location.replace(RoutesData.Login.path);
    }, [unsubscribeUser, setApiConfig]);

    const validadePermission: () => Promise<number> = useCallback(() => {
        return new Promise<number>((resolve, reject) => {
            if (sessionReady) {
                api<ValidadePermissionResponse>({
                    method: "GET",
                    url: Endpoints.VerificarPermissao,
                    useLoading: true,
                }).then((response: AxiosResponse<ValidadePermissionResponse>) => {
                    if (response.status === 200) {
                        subscribeUser({ ...getUser(userCookie), roles: response.data.idFkFuncao } as LoginResponse);
                        resolve(response.data.idFkFuncao);
                    } else {
                        reject(response.data);
                    }
                }).catch((error) => {
                    reject(error);
                });
            } else {
                reject();
            }
        });
    }, [userCookie, sessionReady, api]);

    const getUserId = useCallback(() => {
        const user = getUser(userCookie);
        return user ? JSON.parse(atob(user.token.split('.')[1])).Id : undefined;
    }, [userCookie])

    /* 
        Replaces the usage of the callback "setUserDataSS" and the "setApiConfig" 
        hook to a "setUserDataState" call by automatic sincronizing the Session 
        Storage user's data with the state "userDataState" and setting up 
        the API configuration's token
    */
    useEffect(() => {
        const user = getUser(userCookie);
        if (user) {
            setApiConfig({
                headers: { Authorization: `Bearer ${user.token}` }
            });
            setSessionReady(true);
        }
    }, [
        userCookie,
        setApiConfig,
    ]);

    return (
        <SessionContext.Provider
            value={{
                login,
                logout,
                validadePermission,
                getUserId,
                user: getUser(userCookie),
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

const useSession: () => SessionContextData = () => {
    return useContext(SessionContext);
};

export default useSession;
export { SessionProvider };
export type { LoginResponse, LoginRequest };