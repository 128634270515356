/* eslint-disable import/no-cycle */
import { FC } from 'react';
import AppProvider from './AppProvider';


import Routes from './routes';


const App: FC = () => {

  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
};

export default App;
