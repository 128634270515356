/* eslint-disable react/jsx-key */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  Switch
} from 'react-router-dom';

import useSession from '../hooks/useSession';
import { RoutesData } from './index';

interface IRouteProps extends Omit<ReactDOMRouteProps, "component"> {
  permissions?: number[];
  component: JSX.Element;
  path?: string | string[];
}

interface IRouteWithSubRoutesProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  subroutes: IRouteProps[]
}

const Route: React.FC<IRouteProps> = ({
  permissions,
  component,
  path,
  ...rest
}) => {

  const { user } = useSession();

  const render = useCallback(() => {
    let page: JSX.Element;
    if (permissions !== undefined) {
      if (user) {
        if (permissions.length === 0 || permissions.indexOf(user.roles) > -1) {
          page = component;
        } else {
          page = <Redirect to={RoutesData.Dashboard.path} />;
        }
      } else {
        page = <Redirect to={RoutesData.Login.path} />;
      }
    } else {
      page = component;
    }
    return page;
  }, [permissions, user]);

  return (
    <ReactDOMRoute
      {...rest}
      path={path}
      render={render}
    />
  );
};

const RouteWithSubRoutes: React.FC<IRouteWithSubRoutesProps> = ({ subroutes, ...route }) => {
  return <Route {...route} component={
    <Switch>
      {subroutes.map(props =>
        <Route {...props} />
      )}
    </Switch>
  } />;
}

export type { IRouteProps, IRouteWithSubRoutesProps };
export { RouteWithSubRoutes };
export default Route;
