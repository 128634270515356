/* eslint-disable */
import { createContext, useState } from "react";

interface IContext {
    tabValue?: number,
    setTabValue?: (id: number) => void,
    tipo?: boolean,
    setTipo?: (tipo: boolean) => void
}

export const FeriasContext = createContext<IContext>({});

export default (props: any) => {
    const [tabValue, setTabValue] = useState<number>(0);
    const [tipo, setTipo] = useState<boolean>(true);

    return <FeriasContext.Provider value={{
        tabValue,
        setTabValue,
        tipo,
        setTipo
    }}>
        {props.children}
    </FeriasContext.Provider>
};
