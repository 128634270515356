import { FC, Key, useCallback } from 'react';
import { IconProps } from '@doar/shared/types';
import { getSiblings } from "@doar/shared/methods";
import { useClickOutside } from "@doar/shared/hooks";
import {
    StyledMegaMenu,
    StyledMegaMenuInner,
    StyledMegamenuLabel,
    StyledMegamenuList,
    StyledNavbar,
    StyledNavitem,
    StyledNavlink,
    StyledSubmenu,
    StyledSubNavItem,
    StyledSubNavlink,
    StyledNavlinkDropDownClass
} from './style';

interface INavbarElement {
    key?: Key | null;
    label: string;
    Icon?: FC<IconProps>;
    permissions?: number[];
    path?: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
}

interface INavbarMegaMenuElement extends INavbarElement {
    children: INavbarElement[];
}

interface INavbarFirstLayerElement extends INavbarElement {
    subMenu?: INavbarElement[];
    megaMenu?: INavbarMegaMenuElement[];
}

interface INavbarProps {
    role: number;
    data: INavbarFirstLayerElement[];
}

interface IRenderWithPermissionProps {
    role: number;
    permissions?: number[];
}

const RenderWithPermission: FC<IRenderWithPermissionProps> = ({ children, role, permissions }) => {
    const canRender = useCallback(() => {
        return Array.isArray(permissions) ? (permissions.length === 0 || permissions.indexOf(role) >= 0) : true;
    }, [role, permissions]);

    return <>{canRender() && children}</>
}

const Navbar: FC<INavbarProps> = ({ data, role }) => {

    const handleClick = (e: React.MouseEvent) => {
        let target = e.currentTarget as HTMLElement;
        if (e.currentTarget.children.length > 0) {
            e.preventDefault();
            if (target.nodeName === "A") {
                target = target.parentElement as HTMLElement;
            }
            const submenu = target.querySelector(".submenu");
            const siblings = getSiblings(target);
            submenu?.classList.toggle("open");
            siblings.forEach((sib) => {
                sib.childNodes.forEach((child) => {
                    const childHT = child as HTMLElement;
                    childHT?.classList?.remove("open");
                });
            });
        }
    };

    const handleClose = useCallback(() => {
        const nav = document.querySelector(".navbar");
        const submenu = nav?.querySelectorAll(".submenu");
        submenu?.forEach((el) => el.classList.remove("open"));
    }, []);
    
    const containerRef = useClickOutside<HTMLUListElement>(handleClose);

    return (
        <StyledNavbar ref={containerRef} className="navbar">
            {data.map(firstLayer => <RenderWithPermission role={role} permissions={firstLayer.permissions}>
                <StyledNavitem key={firstLayer.key} onClick={handleClick}>
                    <StyledNavlink 
                        path={firstLayer.path}
                        target={firstLayer.target}
                        className={(!!firstLayer.megaMenu || !!firstLayer.subMenu)? StyledNavlinkDropDownClass : ''}
                    >
                        {firstLayer.Icon && <firstLayer.Icon />}
                        {firstLayer.label}
                    </StyledNavlink>
                    {firstLayer.subMenu && <StyledSubmenu className="submenu">
                        {firstLayer.subMenu.map(element => <RenderWithPermission role={role} permissions={element.permissions}>
                            <StyledSubNavItem key={element.key}>
                                <StyledSubNavlink path={element.path??"#!"} target={element.target}>
                                    {element.Icon && <element.Icon />}
                                    {element.label}
                                </StyledSubNavlink>
                            </StyledSubNavItem>
                        </RenderWithPermission>)}
                    </StyledSubmenu>}
                    {firstLayer.megaMenu && <StyledMegaMenu className="submenu">
                        <StyledMegaMenuInner>
                            {firstLayer.megaMenu.map(megaMenuElement => <RenderWithPermission role={role} permissions={megaMenuElement.permissions}>
                                <StyledMegamenuList key={megaMenuElement.key}>
                                    <StyledMegamenuLabel>
                                        {megaMenuElement.Icon && <megaMenuElement.Icon />}
                                        {megaMenuElement.label}
                                    </StyledMegamenuLabel>
                                    {megaMenuElement.children.map(childrenMMElement => <RenderWithPermission role={role} permissions={childrenMMElement.permissions}>
                                        <StyledSubNavItem key={childrenMMElement.key}>
                                            <StyledSubNavlink path={childrenMMElement.path??"#!"} target={childrenMMElement.target}>
                                                {childrenMMElement.Icon && <childrenMMElement.Icon />}
                                                {childrenMMElement.label}
                                            </StyledSubNavlink>
                                        </StyledSubNavItem>
                                    </RenderWithPermission>)}
                                </StyledMegamenuList>
                            </RenderWithPermission>)}
                        </StyledMegaMenuInner>
                    </StyledMegaMenu>}
                </StyledNavitem>
            </RenderWithPermission>)}
        </StyledNavbar>
    );
}

export type { INavbarProps, INavbarFirstLayerElement };
export default Navbar;