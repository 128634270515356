import React, { createContext, useContext, useCallback, useState } from "react";
import Preloader from "../components/preloader";

interface LoadingContextData {
    addLoading(): void;
    removeLoading(): void;
}

const LoadingContext = createContext<LoadingContextData>({
    addLoading: () => { },
    removeLoading: () => { },
});

const LoadingProvider: React.FC = ({ children }) => {
    const [loadingReqCounter, setLoadingReqCounter] = useState<number>(0);

    const addLoading = useCallback(() => {
        setLoadingReqCounter((prev) => prev + 1);
    }, []);

    const removeLoading = useCallback(() => {
        setLoadingReqCounter((prev) => (prev < 1 ? 0 : prev - 1));
    }, []);

    return (
        <LoadingContext.Provider value={{ addLoading, removeLoading }}>
            {children}
            {loadingReqCounter > 0 && <Preloader />}
        </LoadingContext.Provider>
    );
};

function useLoading(): LoadingContextData {
    return useContext(LoadingContext);
}

export default useLoading;
export { LoadingProvider };
