import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
    SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
} from "@doar/shared/styled";

import { /* StyledLink, */ StyledAnchor } from "./style";

interface IProps extends SpaceProps, ColorProps, TypographyProps, LayoutProps {
    path: string | undefined;
    className?: string | undefined;
    rel?: string | undefined;
    label?: string | undefined;
    target?: "_blank" | "_self" | "_parent" | "_top";
    onClick?: (e: React.MouseEvent) => void;
    variant?: "link1" | "link2" | "link3";
}

const Anchor: React.FC<IProps> = ({
    label,
    path,
    variant,
    onClick,
    target,
    ...rest
}) => {

    const history = useHistory();

    const handleClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        if (path) {
            if ((/^\/(?!\/)/.test(path))) {
                history.push(path);
            } else {
                window.open(path, target);
            }
        }
        onClick?.call({}, e);
    }, [path, target, onClick, history]);

    return <StyledAnchor
        aria-label={label}
        href="#!"
        $variant={variant}
        onClick={handleClick}
        {...rest}
    />;
};

export type { IProps };
export default Anchor;

/* Anchor.defaultProps = {
    target: "_blank",
    rel: "noopener noreferrer",
};

Anchor.displayName = "Anchor"; */
