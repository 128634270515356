/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { X } from 'react-feather';

import { AlertColors } from '../../constants/pallette.json';
import useAlert from '../../hooks/useAlert';
import { AlertCard, AlertCardThemeData, BodyAlert, TitleAlert } from './styles';

enum AlertTypeEnum {
    Info = 0,
    Success = 1,
    Danger = 2
}

interface AlertConfigData {
    id: string;
    title: string;
    message: string;
    type?: AlertTypeEnum;
    canClose?: boolean;
    autoClose?: {
        timeOut: number;
    }
}

const getAlertPopUpTheme: (alertType?: AlertTypeEnum) => AlertCardThemeData = (alertType) => {
    let result: AlertCardThemeData = {
        backgroundColor: ''
    };
    // eslint-disable-next-line default-case
    switch (alertType !== undefined ? +alertType : +AlertTypeEnum.Info) {
        case AlertTypeEnum.Info:
            result = {
                backgroundColor: AlertColors.Info
            }
            break;
        case AlertTypeEnum.Success:
            result = {
                backgroundColor: AlertColors.Success
            }
            break;
        case AlertTypeEnum.Danger:
            result = {
                backgroundColor: AlertColors.Danger
            }
            break;
    }
    return result;
}

const AlertPopUp: React.FC<AlertConfigData> = (params) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { removeAlert } = useAlert();

    useEffect(() => {
        const timer = setTimeout(() => {
            removeAlert(params.id);
        }, params.autoClose?.timeOut??3000);
        
        return () => clearTimeout(timer);
    }, [removeAlert, params]);

    return <AlertCard theme={getAlertPopUpTheme(params.type)}>
        <TitleAlert>
            {params.title}
            {(params?.canClose??true) && <X onClick={() => removeAlert(params.id)} />}
        </TitleAlert>
        <BodyAlert>
            {params.message}
        </BodyAlert>
        {params.children}
    </AlertCard>
}

export { AlertTypeEnum, AlertCard };
export type { AlertConfigData };
export default AlertPopUp;
