/* eslint-disable jsx-a11y/anchor-has-content */
import styled, {
    space,
    color as colorStyles,
    typography,
    layout,
    SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    css,
    themeGet,
} from "@doar/shared/styled";
// import { Link } from "react-router-dom";

interface IProps extends SpaceProps, ColorProps, TypographyProps, LayoutProps {
    $variant?: "link1" | "link2" | "link3";
}

const anchorStyle = css<IProps>`
    ${({ $variant }) =>
        $variant === "link1" &&
        css`
            color: ${themeGet("colors.text")};
            &:hover {
                color: ${themeGet("colors.primary")};
            }
        `}
    ${({ $variant }) =>
        $variant === "link2" &&
        css`
            color: ${themeGet("colors.text2")};
            &:hover {
                color: ${themeGet("colors.primary")};
            }
        `}
    ${({ $variant }) =>
        $variant === "link3" &&
        css`
            color: ${themeGet("colors.text3")};
            &:hover {
                color: ${themeGet("colors.text2")};
            }
        `}

    ${space};
    ${colorStyles};
    ${typography};
    ${layout};
`;

/* export const StyledLink = styled(Link)<IProps>`
    ${anchorStyle};
`; */

export const StyledAnchor = styled((props) => <a {...props} />)<IProps>`
    ${anchorStyle};
`;
