/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from '@doar/shared/styled';
import { darken } from 'polished';

interface AlertCardThemeData {
  backgroundColor: string
}

const AlertCard = styled.div`
    color: ${props => darken(0.6, props.theme.backgroundColor)};
    background-color: ${props => props.theme.backgroundColor};
    border-color: ${props => props.theme.backgroundColor};

    border-radius: 4px;

    padding: 5px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1);
`;

export const ContentAlerts = styled.div`
  position: fixed; 
  z-index: 99999;
  top: 0; 
  right: 0;
`;

export const TitleAlert = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0;
  width: 100%;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  svg{
    cursor: pointer;
  }
`;

export const BodyAlert = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 5px 0;
  width: 100%;
  margin: auto;
  padding: 5px 0;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
`;


export { AlertCard };
export type { AlertCardThemeData };