/* eslint-disable prettier/prettier */
import React, { createContext, useCallback, useContext, useState } from "react";
import axios, { AxiosRequestConfig, AxiosPromise, AxiosResponse } from "axios";

import { ApiDefaultConfig } from "../constants/apiConfig.json";
import useLoading from "./useLoading";

interface APIMiddleware {
    id: number;
    executor(response: AxiosResponse): AxiosPromise;
}

interface APIConfig extends AxiosRequestConfig {
    middlewares: APIMiddleware[];
}

interface APIConfigParam extends AxiosRequestConfig {
    middlewares?: APIMiddleware[];
}

interface APICallParam extends AxiosRequestConfig {
    useLoading?: boolean;
}

interface APIContextData {
    api<T>(config: APICallParam): AxiosPromise<T>;
    setApiConfig(config: APIConfigParam): void;
    setApiDefaultConfig(): void;
    removeMiddlewareById(id: number): void;
}

const APIContext: React.Context<APIContextData> = createContext({
    api: (config: APICallParam) => axios({ ...ApiDefaultConfig, ...config }),
    setApiConfig: () => { },
    setApiDefaultConfig: () => { },
    removeMiddlewareById: () => { },
} as APIContextData);

const APIProvider: React.FC = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { addLoading, removeLoading } = useLoading();
    const [apiConfig, setApiConfig] = useState<APIConfig>({
        ...ApiDefaultConfig,
        middlewares: [],
    });

    const setApiDefaultConfig: () => void = useCallback(() => {
        setApiConfig({ ...ApiDefaultConfig, middlewares: [] });
    }, []);

    const setApiConfigContext: (config: APIConfigParam) => void = useCallback(
        (config: APIConfigParam) => {
            setApiConfig((prev) => {
                if (config.middlewares) {
                    const middlewares: APIMiddleware[] = prev.middlewares
                        .concat(config.middlewares)
                        .sort((e) => e.id);
                    return { ...prev, ...config, middlewares };
                }
                return { ...prev, ...config };
            });
        },
        []
    );

    const executeMiddlewares: (
        response: AxiosResponse,
        i: number
    ) => AxiosPromise = useCallback(
        (response, i) => {
            if (i < apiConfig.middlewares.length) {
                return new Promise<AxiosResponse>((resolve, reject) => {
                    apiConfig.middlewares[i]
                        .executor(response)
                        .then((midResponse) => {
                            executeMiddlewares(midResponse, i + 1)
                                .then((nextResponse) => {
                                    resolve(nextResponse);
                                })
                                .catch((nextError) => {
                                    reject(nextError);
                                });
                        })
                        .catch((midError) => {
                            reject(midError);
                        });
                });
            }
            return new Promise<AxiosResponse>((resolve) => {
                resolve(response);
            });
        },
        [apiConfig]
    );

    const api: (config: APICallParam) => AxiosPromise = useCallback(
        (config: APICallParam) => {
            return new Promise<AxiosResponse>((resolve, reject) => {
                if (config.useLoading === true) {
                    addLoading();
                    axios({ ...apiConfig, ...config })
                        .then((response) => {
                            executeMiddlewares(response, 0)
                                .then((midResponse) => {
                                    resolve(midResponse);
                                })
                                .catch((midError) => {
                                    reject(midError);
                                })
                                .finally(() => {
                                    removeLoading();
                                });
                        })
                        .catch((error) => {
                            reject(error);
                            removeLoading();
                        });
                } else {
                    axios({ ...apiConfig, ...config })
                        .then((response) => {
                            executeMiddlewares(response, 0)
                                .then((midResponse) => {
                                    resolve(midResponse);
                                })
                                .catch((midError) => {
                                    reject(midError);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            });
        },
        [apiConfig, executeMiddlewares, addLoading, removeLoading]
    );

    const removeMiddlewareById: (id: number) => void = useCallback((id) => {
        setApiConfig((prev) => ({
            ...prev,
            middlewares: prev.middlewares.filter((e) => e.id !== id),
        }));
    }, []);

    return (
        <APIContext.Provider
            value={{
                api,
                setApiConfig: setApiConfigContext,
                setApiDefaultConfig,
                removeMiddlewareById,
            }}
        >
            {children}
        </APIContext.Provider>
    );
};

const useAPI: () => APIContextData = () => {
    return useContext(APIContext);
};

export default useAPI;
export { APIProvider };
export type { APIContextData };
