/* eslint-disable */

import React from "react";
// import ReactDOM from "react-dom";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { DndProvider } from 'react-dnd';
import { Provider } from "react-redux";
import { ThemeProvider, theme } from "@doar/shared/styled";
import { GlobalStyle } from "@doar/shared/css";
import { store } from "./redux/store";
import { APIProvider } from "./hooks/useAPI";
import { LoadingProvider } from "./hooks/useLoading";
import { SessionProvider } from "./hooks/useSession";
import { AlertProvider } from "./hooks/useAlert";
import FeriasContext from "./contexts/FeriasContext";
import { RouterProvider } from "./hooks/useRouter";


const AppProvider: React.FC = ({ children }) => {
    return (
        <Provider store={store}>
            <LoadingProvider>
                <AlertProvider>
                    <APIProvider>
                        <SessionProvider>
                            <RouterProvider>
                                <FeriasContext>
                                    <ThemeProvider theme={theme}>
                                        <GlobalStyle />
                                        {children}
                                    </ThemeProvider>
                                </FeriasContext>
                            </RouterProvider>
                        </SessionProvider>
                    </APIProvider>
                </AlertProvider>
            </LoadingProvider>
        </Provider>
    )
}

export default AppProvider;