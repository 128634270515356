/* eslint-disable import/no-cycle */

import { FC, Suspense, lazy, useEffect, useCallback } from 'react';

import { Switch } from 'react-router-dom';

import Roles from '../constants/roles.json';

import useSession from '../hooks/useSession';
import useRouter from '../hooks/useRouter';

import Route, { IRouteProps } from './Route';
import Preloader from '../components/preloader';

const Atividade = lazy(() => import("../pages/tasks"));
const CadastroVagas = lazy(() => import("../pages/cadastroVagas"));
const Calendar = lazy(() => import("../pages/apps/calendar"));
const Candidatos = lazy(() => import("../pages/apps/candidatos"));
// const Candidatos = lazy(() => import("../pages/connections"));
const Certificado = lazy(() => import("../pages/certificado"));
// const Chat = lazy(() => import("../pages/apps/chat"));
const Contato = lazy(() => import("../pages/contact"));
// const Contatos = lazy(() => import("../pages/apps/contacts"));
// const DashboardFour = lazy(() => import("../pages/dashboard-four"));
// const DashboardOne = lazy(() => import("../pages/dashboard-one"));
// const DashboardThree = lazy(() => import("../pages/dashboard-three"));
// const DashboardTwo = lazy(() => import("../pages/dashboard-two"));
const DetalhesVaga = lazy(() => import("../pages/vagasView"));
const DocumentoUsuario = lazy(() => import("../pages/documento-usuario"));
const Empresas = lazy(() => import("../pages/empresas"));
const EnvioDocumentos = lazy(() => import("../pages/envio-documento"));
const Error500 = lazy(() => import("../pages/error-500"));
const Error503 = lazy(() => import("../pages/error-503"));
const Error505 = lazy(() => import("../pages/error-505"));
const ErrorNotFound = lazy(() => import("../pages/error-404"));
// const Events = lazy(() => import("../pages/events"));
const FAQ = lazy(() => import("../pages/help-center"));
const FeriasVendaSolicitadaView = lazy(() => import("../pages/FeriasSolicitada"));
// const FileManager = lazy(() => import("../pages/apps/file-manager"));
const FinancialBills = lazy(() => import("../pages/financial-bills"));
const FinancialFixedBills = lazy(() => import("../pages/financial-fixed-bills"));
const FinancialReceipt = lazy(() => import("../pages/financial-receipt"));
const ForgotPassword = lazy(() => import("../pages/forgot-password"));
const Funcoes = lazy(() => import("../pages/function"));
const GerenciarCandidatos = lazy(() => import("../pages/apps/gerenciar-candidatos"));
const GestaoContraCheque = lazy(() => import("../pages/gestao-contracheque"));
const GestaoFerias = lazy(() => import("../pages/gestao-ferias"));
const RelatorioFerias = lazy(() => import("../pages/relatorio-ferias"));
const Habilidades = lazy(() => import("../pages/skills"));
const HabilidadesRH = lazy(() => import("../pages/skill-rh"));
const TipoDocumentos = lazy(() => import("../pages/tipoDocumento"));
const BeneficiosRH = lazy(() => import("../pages/benefits-rh"));
// const Invoice = lazy(() => import("../pages/invoice"));
// const Mail = lazy(() => import("../pages/apps/mail"));
const NoVacancy = lazy(() => import("../pages/noVacancy"));
const Perfil = lazy(() => import("../pages/profile-view"));
const PerfilBusca = lazy(() => import("../pages/perfil-view"));
// const Pricing = lazy(() => import("../pages/pricing"));
const Projetos = lazy(() => import("../pages/projetos"));
const Relatorio = lazy(() => import("../pages/apps/relatorio"));
const RelatorioFaturamento = lazy(() => import("../pages/apps/relatorio-faturamento"));
const RelatorioContasAPagar = lazy(() => import("../pages/relatorioContasAPagar"));
// const RelatorioContasFixas = lazy(() => import("../pages/relatorioContasFixas"));
const RelatorioMovDiaria = lazy(() => import("../pages/relatorioMovDiaria"));
const RelatorioRecebimentos = lazy(() => import("../pages/relatorioRecebimentos"));
const RelatorioUsuario = lazy(() => import("../pages/apps/relatorio-user"));
const Seniority = lazy(() => import("../pages/seniority"));
const SignIn = lazy(() => import("../pages/signin"));
// const SignUp = lazy(() => import("../pages/signup"));
const SolicitarFerias = lazy(() => import("../pages/SolicitarFerias"));
// const Timeline = lazy(() => import("../pages/timeline"));
const TagFinanceiro = lazy(() => import("../pages/tagFinanceiro"));
const TipoContato = lazy(() => import("../pages/contactType"));
const UsuarioContracheque = lazy(() => import("../pages/user-contracheque"));
const UsuarioHabilidades = lazy(() => import("../pages/userSkills"));
const Usuarios = lazy(() => import("../pages/users"));
const VagaCandidatoSuccess = lazy(() => import("../components/candidato-vaga-success"));
const Vagas = lazy(() => import("../pages/groups"));
const VerifyAccount = lazy(() => import("../pages/verify-account"));
const VagaFormulario = lazy(() => import("../pages/vagaForm"));
const Feriado = lazy(() => import("../pages/feriado"));
const GestaoHoraExtra = lazy(() => import("../pages/work-overtime"));
const GestaoApontamentos = lazy(() => import("../pages/appointments"));


const permissionAll: number[] = [];

interface IRoutesDataObject extends IRouteProps {
  label: string;
  path: string;
}

const RoutesData = {

  // No permition

  CandidaturaRealizada: {
    label: "Candidatura realizada",
    path: "/application-carried-out",
    exact: true,
    permissions: undefined,
    component: <VagaCandidatoSuccess vagasPath="/vacancies" />
  },
  DetalhesVaga: {
    label: "Detalhes da vaga",
    path: "/vacancy-details/:id",
    exact: true,
    permissions: undefined,
    component: <DetalhesVaga />
  },
  ErroQuinhentos: {
    label: "Erro quinhentos",
    path: "/error-500",
    exact: true,
    permissions: undefined,
    component: <Error500 />
  },
  ErroQuinhentosECinco: {
    label: "Erro quinhentos e cinco",
    path: "/error-505",
    exact: true,
    permissions: undefined,
    component: <Error505 />
  },
  ErroQuinhentosETres: {
    label: "Erro quinhentos e tres",
    path: "/error-503",
    exact: true,
    permissions: undefined,
    component: <Error503 />
  },
  EsqueciMinhaSenha: {
    label: "Esqueci a minha senha",
    path: "/forgot-password",
    exact: true,
    permissions: undefined,
    component: <ForgotPassword />
  },
  FAQ: {
    label: "Faq",
    path: "/faq",
    exact: true,
    permissions: undefined,
    component: <FAQ />
  },
  Login: {
    label: "Login",
    path: "/",
    exact: true,
    permissions: undefined,
    component: <SignIn />
  },
  VagaFormulario: {
    label: "Formulario de vaga",
    path: "/vacancy-form/:id",
    exact: true,
    permissions: undefined,
    component: <VagaFormulario />
  },
  Vagas: {
    label: "Vagas",
    path: "/vacancies",
    exact: true,
    permissions: undefined,
    component: <Vagas />
  },

  // Full permition

  // On dev
  /* AppsBatePapo: {
    label: "Bate papo",
    path: "/apps/chat",
    exact: true,
    permissions: permissionAll,
    component: <Chat />,
  }, */
  // On dev
  /* AppsContatos: {
    label: "Contatos",
    path: "/apps/contacts",
    exact: true,
    permissions: permissionAll,
    component: <Contatos />,
  }, */
  // On dev
  /* AppsEmail: {
    label: "E-mail",
    path: "/apps/mail",
    exact: true,
    permissions: permissionAll,
    component: <Mail />,
  }, */
  // On dev
  /* AppsGerenciadorDeArquivos: {
    label: "Gerenciador de arquivos",
    path: "/apps/file-manager",
    exact: true,
    permissions: permissionAll,
    component: <FileManager />,
  }, */
  Certificados: {
    label: "Certificados",
    path: "/certificates",
    exact: true,
    permissions: permissionAll,
    component: <Certificado />,
  },
  ConsultarContracheque: {
    label: "Consultar contracheque",
    path: "/consult-paychecks",
    exact: true,
    permissions: permissionAll,
    component: <UsuarioContracheque />,
  },
  Contatos: {
    label: "Contatos",
    path: "/contacts",
    exact: true,
    permissions: permissionAll,
    component: <Contato />,
  },
  Dashboard: {
    label: "Dashboard",
    path: "/dashboard",
    exact: true,
    permissions: permissionAll,
    component: <Calendar />,
  },
  // On dev
  /* DashboardDois: {
    label: "Dashboard 2",
    path: "/dashboard-two",
    exact: true,
    permissions: permissionAll,
    component: <DashboardTwo />,
  }, */
  // On dev
  /* DashboardQuatro: {
    label: "Dashboard 4",
    path: "/dashboard-four",
    exact: true,
    permissions: permissionAll,
    component: <DashboardFour />,
  }, */
  // On dev
  /* DashboardTres: {
    label: "Dashboard 3",
    path: "/dashboard-three",
    exact: true,
    permissions: permissionAll,
    component: <DashboardThree />,
  }, */
  // On dev
  /* DashboardUm: {
    label: "Dashboard 1",
    path: "/dashboard-one",
    exact: true,
    permissions: permissionAll,
    component: <DashboardOne />,
  }, */
  DocumentosUsuarios: {
    label: "Documentos usuários",
    path: "/user-document",
    exact: true,
    permissions: permissionAll,
    component: <DocumentoUsuario />,
  },
  // On dev
  /* Eventos: {
    label: "Eventos",
    path: "/events",
    exact: true,
    permissions: permissionAll,
    component: <Events />,
  }, */
  // On dev
  /* Fatura: {
    label: "Fatura",
    path: "/invoice",
    exact: true,
    permissions: permissionAll,
    component: <Invoice />,
  }, */
  // On dev
  /* LinhaDoTempo: {
    label: "Linha do tempo",
    path: "/timeline",
    exact: true,
    permissions: permissionAll,
    component: <Timeline />,
  }, */
  Perfil: {
    label: "Perfil",
    path: "/profile",
    exact: true,
    permissions: permissionAll,
    component: <Perfil />,
  },
  PesquisarPerfil: {
    label: "Pesquisar perfil",
    path: "/profile-search",
    exact: true,
    permissions: permissionAll,
    component: <PerfilBusca />,
  },
  // On dev
  /* Precos: {
    label: "Preços",
    path: "/pricing",
    exact: true,
    permissions: permissionAll,
    component: <Pricing />,
  }, */
  RelatorioApontamentosUsuario: {
    label: "Relatório apontamentos",
    path: "/user-report",
    exact: true,
    permissions: permissionAll,
    component: <RelatorioUsuario />,
  },
  SolicitarFerias: {
    label: "Solicitar férias",
    path: "/vacation-request",
    exact: true,
    permissions: permissionAll,
    component: <SolicitarFerias />,
  },
  // On dev
  /* SignUp: {
    label: "Sign up",
    path: "/signup",
    exact: true,
    permissions: permissionAll,
    component: <SignUp />,
  }, */
  SolicitacaoSucesso: {
    label: "Solicitação bem sucedida",
    path: "/successful-request",
    exact: true,
    permissions: permissionAll,
    component: <FeriasVendaSolicitadaView />,
  },
  NoVacancy: {
    label: "Aguardando Vagas",
    path: "/no-vacancy",
    exact: true,
    permissions: permissionAll,
    component: <NoVacancy />,
  },
  UsuarioHabilidades: {
    label: "Habilidades do usuário",
    path: "/user-skills",
    exact: true,
    permissions: permissionAll,
    component: <UsuarioHabilidades />,
  },
  VerificarConta: {
    label: "Verificar conta",
    path: "/verify-account",
    exact: true,
    permissions: permissionAll,
    component: <VerifyAccount />,
  },

  // AnalistaDeRH

  CadastroVagas: {
    label: "Cadastro Vagas",
    path: "/vacancies-registration",
    exact: true,
    permissions: [Roles.Administrador, Roles.AnalistaDeRH],
    component: <CadastroVagas />,
  },
   CadastroHabilidades: {
     label: "Habilidades Candidatos",
     path: "/skill-rh",
     exact: true,
     permissions: [Roles.Administrador, Roles.AnalistaDeRH],
     component: <HabilidadesRH />,
   },
  CadastroBeneficios: {
   label: "Cadastro Benefícios",
   path: "/benefits-rh",
   exact: true,
   permissions: [Roles.Administrador, Roles.AnalistaDeRH],
   component: <BeneficiosRH />,
  },
  Candidatos: {
    label: "Candidatos",
    path: "/candidates",
    exact: true,
    permissions: [Roles.Administrador, Roles.AnalistaDeRH],
    component: <Candidatos />,
  },
  GerenciarCandidatos: {
    label: "Gerenciar candidatos",
    path: "/manage-candidates",
    exact: true,
    permissions: [Roles.Administrador, Roles.AnalistaDeRH],
    component: <GerenciarCandidatos />,
  },

  // Financeiro Administrativo
  GestaoApontamentos: {
    label: "Apontamentos",
    path: "/appointments",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <GestaoApontamentos />,
  },
  ContasAPagarFinanceiro: {
    label: "Contas",
    path: "/financial-bills",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <FinancialBills />,
  },
  ContasFixasFinanceiro: {
    label: "Contas Fixas",
    path: "/financial-fixed-bills",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <FinancialFixedBills />,
  },
  TagFinanceiro: {
    label: "Cliente e Fornecedor",
    path: "/financial-tag",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <TagFinanceiro />,
  },
  RecebimentoFinanceiro: {
    label: "Recebimentos",
    path: "/financial-receipt",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <FinancialReceipt />,
  },
  RelatorioContasAPagar: {
    label: "Relatório contas a pagar",
    path: "/financial-bills-report",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <RelatorioContasAPagar />,
  },
  // RelatorioContasFixas: {
  //   label: "Relatório contas fixas",
  //   path: "/financial-fixed-bills-report",
  //   exact: true,
  //   permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
  //   component: <RelatorioContasFixas />,
  // },
  RelatorioMovDiaria: {
    label: "Movimentação diária",
    path: "/daily-movement-report",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <RelatorioMovDiaria />,
  },
  RelatorioRecebimentos: {
    label: "Relatório Recebimentos",
    path: "/receipt-report",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <RelatorioRecebimentos />,
  },
  EnviarContracheque: {
    label: "Enviar contracheque",
    path: "/send-paycheck",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <GestaoContraCheque />,
  },
  EnviarDocumentos: {
    label: "Enviar documentos",
    path: "/send-documents",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <EnvioDocumentos />,
  },
  TipoDocumentos: {
    label: "Tipo Documentos",
    path: "/tipo-documentos",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <TipoDocumentos />,
  },
  GestaoDeFerias: {
    label: "Gestão de férias",
    path: "/vacation-management",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <GestaoFerias />,
  },
  RelatorioFerias: {
    label: "Relatório férias",
    path: "/report-vacation",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <RelatorioFerias />,
  },
  RelatorioApontamentos: {
    label: "Relatório Apontamentos",
    path: "/report",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Relatorio />,
  },
  RelatorioFaturamento: {
    label: "Relatório Faturamento",
    path: "/report-billing",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <RelatorioFaturamento />,
  },
  Usuarios: {
    label: "Usuários",
    path: "/users",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Usuarios />,
  },
  Feriado: {
    label: "Feriados",
    path: "/feriados",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Feriado />,
  },
  GestaoHoraExtra: {
    label: "Gestão Hora Extra",
    path: "/work-overtime",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <GestaoHoraExtra />,
  },
  Empresas: {
    label: "Empresas",
    path: "/companies",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Empresas />,
  },
  Funcoes: {
    label: "Funções",
    path: "/functions",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Funcoes />,
  },
  Senioridades: {
    label: "Senioridades",
    path: "/seniority",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Seniority />,
  },
  Habilidades: {
    label: "Habilidades",
    path: "/skills",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <Habilidades />,
  },
  TiposDeContato: {
    label: "Tipos de contato",
    path: "/contact-type",
    exact: true,
    permissions: [Roles.Administrador, Roles.FinanceiroAdministrativo],
    component: <TipoContato />,
  },
  // Gerente de Projetos
  Atividades: {
    label: "Atividades",
    path: "/activities",
    exact: true,
    permissions: [Roles.Administrador,Roles.GerenteDeProjetos],
    component: <Atividade />,
  },

  // Only Administrador
  Projetos: {
    label: "Projetos",
    path: "/projects",
    exact: true,
    permissions: [Roles.Administrador],
    component: <Projetos />,
  }

};

const routesRenderer = <>
  {Object.values(RoutesData).map((value: IRoutesDataObject) =>
    <Route
      {...value}
      key={value.path}
    />
  )}
</>;

const Routes: FC = () => {

  const { addListener, removeListener } = useRouter();
  const { validadePermission } = useSession();

  const onChange = useCallback(() => {
    validadePermission().then(() => { }).catch(() => { });
  }, [validadePermission]);

  useEffect(() => {
    const id = addListener(onChange);
    return () => {
      removeListener(id);
    };
  }, [addListener, removeListener, onChange]);

  return (
    <Suspense fallback={<Preloader />}>
      <Switch>
        {routesRenderer}
        <Route path="*" component={<ErrorNotFound />} />
      </Switch>
    </Suspense>
  );
};

export type { IRoutesDataObject };
export { RoutesData };
export default Routes;
