import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line import/no-cycle
import AlertPopUp, { AlertConfigData, AlertTypeEnum } from '../components/alert';
import { ContentAlerts } from "../components/alert/styles";


interface AlertContextData {
  addAlert(message: Omit<AlertConfigData, 'id'>): void;
  removeAlert(id: string): void;
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData);

const AlertProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<AlertConfigData[]>([]);

  const addAlert = useCallback((config: Omit<AlertConfigData, 'id'>) => {
    const alert: AlertConfigData = {
      ...config,
      id: uuid()
    };

    setMessages(state => [...state, alert]);
  }, []);

  const removeAlert = useCallback((id: string) => {
    setMessages(state => state.filter(text => text.id !== id));
  }, []);


  return (
    <AlertContext.Provider value={{ addAlert, removeAlert }}>
      {children}
      <ContentAlerts>
        {messages.map((message, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <AlertPopUp key={`alert-${i}`} {...message} />
        ))}
      </ContentAlerts>
    </AlertContext.Provider>
  );
};

function useAlert(): AlertContextData {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider');
  }

  return context;
}

export default useAlert;
export { AlertProvider, AlertTypeEnum };

