import { FC, useState, useCallback, useEffect, useContext, createContext } from 'react';
import { v4 as uuid } from 'uuid';
import { Location } from 'history';
import { BrowserRouter, useHistory } from 'react-router-dom';

type IRouterAction = (location: Location) => void;

interface IRouter {
    id: string;
    action: IRouterAction;
}

interface IRouterContextData {
    addListener: (action: IRouterAction) => string;
    removeListener: (id: string) => IRouterAction | undefined;
}

const RouterContext = createContext<IRouterContextData>({
    addListener: () => "",
    removeListener: () => undefined,
});

const RouterProviderChild: FC = ({ children }) => {

    const history = useHistory();
    const [listeners, setListeners] = useState<IRouter[]>([]);

    const onChange = useCallback((location: Location) => {
        listeners.forEach((listener) => {
            listener.action(location);
        });
    }, [listeners]);

    useEffect(() => {
        return history?.listen((location) => {
            onChange({
                hash: location.hash,
                key: location.key ?? '',
                pathname: location.pathname,
                search: location.search,
                state: location.state,
            })
        });
    }, [history, onChange]);

    const addListener = useCallback((action: IRouterAction) => {
        const id = uuid();
        setListeners(prevListeners => [{ id, action }, ...prevListeners]);
        return id;
    }, []);

    const removeListener = useCallback((id: string) => {
        let listener: IRouter | undefined;
        setListeners(prevListeners => prevListeners.reduce((prev, curr) => {
            let next = prev;
            if (curr.id === id) {
                listener = curr;
            } else {
                next = [...prev, curr];
            }
            return next;
        }, [] as IRouter[]));
        return listener?.action;
    }, []);

    return (
        <RouterContext.Provider value={{ addListener, removeListener }}>
            {children}
        </RouterContext.Provider>
    );
}

const RouterProvider: FC = ({ children }) => {

    return (
        <BrowserRouter>
            <RouterProviderChild>
                {children}
            </RouterProviderChild>
        </BrowserRouter>
    );
}

function useRouter(): IRouterContextData {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('useRouter must be used within a RouterProvider');
    }

    return context;
}

export default useRouter;
export { RouterProvider };
export type { IRouter, IRouterAction };
